.product-container {
  margin-top: -850px;
  margin-right: 70px;
  padding-left: 300px;
  margin-left: 50px;
  margin-right: 20px;
  display: none;
}

.product-row {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.product-card {
  border: none;
  border-radius: 5px;
  padding: 20px;
  width: calc(33.33% - 20px); /* Adjust width to fit three cards in a row */
  box-shadow: 0px 0px 10px white;
}

.product-card img {
  width: 100%;
  border-radius: 2px;
}

.product-info {
  text-align: left;
}
.textproduct{
  margin-top: -19px;
}

.price {
  margin-top: 13px;
  font-weight: 500;
  
}

.Hide{
  margin-top: -3560px;
  margin-left: 1099px;
  font-weight: 400;
  font-size: 15px;
}
.do{
  
  font-size: 0.3px;
  font-weight: 100;
  font-style:oblique;
  margin-top: -10px;
}
.custom-footer {
  background-color: black;
  color: white;
  padding: 50px 0;
  margin-top: 80px;
  height: 193px;
  width: 100%;
  
  
}

.custom-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 90%;
  margin: 0 auto;
  text-align: center;
}

.custom-column {
  flex-basis: 20%;
  padding: 0 20px;
  margin-bottom: 20px;
  margin-top: -20px;
}
.ee h4{
  font-weight: 500;
}
.custom-column h3 {
  font-size: 16px;
  color: white;
  font-weight: 600;
}

.custom-column p {
  font-size: 14px;
  margin: 5px 0;
  color: rgb(209, 210, 212);
  
}

.custom-column p:last-child {
  margin-bottom: 0;
}

.custom-horizontal-line {
  border-top: 1px dashed rgb(246, 237, 237);
  margin: 20px 0;
}

.custom-footer-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
 
}

.custom-footer-bottom p {
  font-size: 12px;
  color: rgb(209, 210, 212);
  margin-right: -1260px;
  
}
.ex{
  color: rgb(222, 74, 74);
  margin-top: -17px;
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif
  
  
}

.small{
  margin-top: -20px;
  color: rgb(134, 134, 134);
  font-weight: 500;
}
.smalll{
  color: rgb(134, 134, 134);
  font-weight: 500;
}

/* FilterComponent.css */

.filter-container {
  width: 200px;
  margin: 20px auto;
  padding: 20px;
  
  border-radius: 8px;
  background-color: #fff;
  margin-left: 20px;
  margin-top: 120px;
}

.filter-container h2 {
  margin-bottom: 20px;
  font-size: 20px;
}

.filter-category {
  margin-bottom: 20px;
}

.filter-category label {
  display: block;
  font-weight: bold;
}

.filter-category select,
.filter-category input[type="range"] {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #e0e0e0;
  background-color: #f9f9f9;
  font-size: 16px;
  outline: none;
}

.filter-category input[type="range"] {
  margin-top: 5px;
}

.filter-category select:focus,
.filter-category input[type="range"]:focus {
  border-color: #007bff;
}

#color {
  display: flex;
}

.color-option {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 5px;
  border: 1px solid #ccc;
  cursor: pointer;
}

.color-option.black { background-color: #000; }
.color-option.white { background-color: #fff; }
/* Add more color classes as needed */
/* RelatedStoriesComponent.css */

.related-stories {
  margin-top: -30px;

}

.related-stories h2 {
  font-size: 24px;
  margin-bottom: 20px;
  margin-top: 3660px;
  margin-left: 106px;
}

.story {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  margin-left: 100px;
}

.story2 img {
  width: 300px;
  height: 300px;
  margin-left: 210px;
  margin-right: 20px;
  margin-bottom: 80px;
  margin-top:5px ;
}
.story1 img {
  width: 300px;
  height: 300px;
  margin-left: 100px;
  margin-right: 20px;
  margin-bottom: 80px;
  margin-top: 5px;
}

.story-info {
  flex-grow: 1;
  margin-left: 180px;
  margin-top: -60px;
  
}
.story1 h3{
  font-weight: 600;
  
}
.story2 h3 {
  font-weight: 600;
}
.story h3 {
  font-size: 10px;
  
  margin-bottom: 5px;
  margin-left: 100px;
}



.related-stories {
  margin-top: 30px;
  margin-left: 265px;
}

.related-stories h2 {
  font-size: 24px;
  margin-bottom: 30px;
}

.story-container {
  display: flex;
  flex-wrap: wrap; /* Allow content to wrap */
  margin-left: -100px;
  
}

.story {
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.image1 {
  width: 100px;
  height: 100px;
  margin-left: -50px;
  margin-right: 70px;
  /* Add styles specific to image1 */
}

.image2 {
  width: 300px;
  height: 120px;
  
  margin-right: 50px;
  /* Add styles specific to image2 */
}

.story .story-info {
  flex-grow: 1;
}

.story h3 {
  font-size: 18px;
  margin-bottom: 50px;

}
.story2 img {
width: 380px;
}
.story1 img{
  margin-left: -5px;
  width: 380px;
}
.story2 p {
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif

}
.story-info1{
  margin-top: -64px;
  margin-left: 220px;
}
.story-info {
  margin-left: 100px;
  
}
.story-info{
  margin-top: -64px;
}

.story-info1 p {
  margin-top: -80px;
  margin-left: -8px;
  font-size: 19px;
  color: rgb(165, 164, 164);
  font-weight: 500;
}
.story-info1 h3{
  margin-left: -10px;
}
.story1 h3{
  margin-left:-100px;
  font-style: oblique;
  
}

.story2 h3{
  font-style: oblique;
  
}
.story1 p {
  margin-top: -80px;
  margin-left: -102px;
  font-size: 19px;
  color: rgb(165, 164, 164);
  font-weight: 500;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}
.newimg {
  padding-bottom: 10px;
  padding-left: 0px;
  margin-bottom: -20px; /* Reduce margin bottom */
  margin-left: 100px; /* Adjust margin left */
  width: calc(39.83% - 10px); /* Adjust width and reduce gap */
  margin: 5px; /* Adjust margin */
  float: right;
  box-sizing: border-box;
  margin-right: -180px; /* Adjust margin right */
  padding-top: 10px;
}

.filter-container {
margin-top: 0px;
    
    overflow: hidden; /* Ensure that any content outside the container is hidden */
  margin-left: -10px;
  
}
.filter-container {
  position: relative;
  top: 0;
  transition: top 0.3s ease; /* Add smooth transition effect */
}

.yoi{
  height: 60px;
  margin-left: 1000px;
  margin-top: 20px;
}
.product-card {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
  width: 300px;
}

.product-card:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.product-card img {
  width: 200px; /* Adjust width as needed */
  height: 200px; /* Adjust height as needed */
  border-radius: 8px;
  object-fit: cover; /* Ensure the image covers the entire space */
 
}

.product-info {
  padding: 10px 0;
}

.price {
  font-weight: bold;
  color: #333; /* Adjust color for better readability */
}

.product-actions {
  margin-top: 12px;
}

.btn-add-to-cart {
  background-color: #4CAF50;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.btn-add-to-cart:hover {
  background-color: #252625;
}

.product-card .product-actions .btn-add-to-cart {
  background-color: #4CAF50;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.product-card .product-actions .btn-add-to-cart:hover {
  background-color: #393a39;
}
.product-card img {
  width: 100%; /* Ensure the image fills its container */
  max-height: 200px; /* Set maximum height for the image */
  border-radius: 8px;
  /* Remove object-fit property */
}
.yoi {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px; /* Adjust margin as needed */
}

.yoi input[type="text"] {
  padding: 10px 20px;
  font-size: 16px;
  border: 2px solid #ccc;
  border-radius: 5px;
  outline: none;
  transition: border-color 0.3s ease;
}

.yoi input[type="text"]:focus {
  border-color: #007bff; /* Change border color on focus */
}

/* Placeholder style */
.yoi input[type="text"]::placeholder {
  color: #999;
}

/* Adjust the width of the input field */
.yoi input[type="text"] {
  width: 300px; /* Adjust width as needed */
}


.product-card {
  
  color: #fff;
  border-radius: 10px;
  padding: 20px;
  width: 300px;

}

.product-card.large {
  width: 350px;
}

.product-info h4 {
  margin: 0;
}

.product-info .price {
  margin: 5px 0;
}

.product-actions {
  margin-top: 20px;
}

.btn-add-to-cart {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.btn-add-to-cart:hover {
  background-color: #0056b3;
}

.cart-content {
  
  font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  background-color: #f0eded;
  color: #333333;
  border-radius: 10px;
  padding: 20px;
  width: 305px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 999;
  margin-top: 10px;
  margin-right: 19px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3); /* Keeping the box-shadow */
  border:2px solid rgb(189, 187, 187);
}

.cart-item {
  padding: 15px;
  margin-top: 15px;
  display: flex;
  align-items: center;
  background-color: #e4e0e0; /* Light gray background */
  border-radius: 10px;
}

.cart-item img {
  width: 50px;
  height: 50px;
  margin-right: 15px;
  border-radius: 20%;
  object-fit: cover;
}

.cart-item p {
  margin: 0;
}

.cart-item p.price {
  font-weight: bold;
  color: #3ab03a; /* Green color */
}

.total {
  margin-top: 20px;
  font-weight: bold;
  font-size: 1.2em;
  color: #3ab03a; /* Green color */
}
.pay p {
  margin-top: 4px;
}
.pay {
  display: block;
  margin-top: 20px;
  padding: 7px 13px;
  background-color: #3ab03a; /* Green color */
  border-radius: 10px;
  font-family: 'Trebuchet MS', sans-serif;
  color: #ffffff; /* White color */
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
  height:25px
}

.pay:hover {
  background-color: #222322; /* Darker green on hover */
}

.btn-back {
  border: none;
  display: block;
  margin-top: 10px;
  padding: 10px 20px;
  border-radius: 30px;
  background-color: #ece6e6; /* Red color */
  color: #1e1d1d; /* White color */
  font-weight: bold;
  text-decoration: underline;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-left:74px ;
  background: none;
}
.pay :hover{
  text-align: center;
  padding: 0.1px;
  color: #f0eded;
  border-radius: 5px;
}
.product-name {
  font-size: 16px;
  font-weight: bold;
  color: #000;
}
.nav2{
  display: inline-flex;
    gap: 40px;
    position: absolute;
    margin-top: -80px;
    background-color: rgb(255, 255, 255);
    margin-left: 410px;
    padding-right: 10px;
    font-weight: 600;
    color: black;
}
.logo img {
  height: 42px; /* Adjust this value as needed */
  width: 40px;
  position: absolute;
  margin-left: 40px;
  
  border-radius: 4px;
  margin-top: -90px;
}
.name img {
    
margin-top: -600px;
  width: 96px;

}
